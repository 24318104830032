<template>
    <v-sheet>
        <v-btn rounded text :disabled="!prevFlag" color="primary" @click="goPrev">Prev</v-btn>
        <template v-for="(pageItem, pageIdx) in pages">
            <v-btn
                fab
                small
                elevation="0"
                class="mx-1"
                :key="'pages_' + pageIdx"
                :color="pageItem == pageNo ? 'primary' : ''"
                @click="goPage(pageItem)"
            >{{ pageItem }}
            </v-btn>
        </template>
        <v-btn rounded text :disabled="!nextFlag" color="primary" @click="goNext">Next</v-btn>
    </v-sheet>
</template>
<script>
export default {
    props: {
        pageNo: Number,
        totalPageCount: Number
    },
    data: () => ({
        pageBlockCount: 5
    }),
    computed: {
        pages() {
            let ret = []
            let dispCount = (this.totalPageCount < this.pageBlockCount) ? this.totalPageCount : this.pageBlockCount
            let startNo = this.pageNo - 2
            if (startNo < 1) {
                startNo = 1
            } else if (startNo + dispCount - 1 > this.totalPageCount) {
                startNo = this.totalPageCount - dispCount + 1
            }

            for (let i = startNo; i < startNo + dispCount; i++) {
                ret.push(i)
            }

            return ret
        },
        prevFlag() {
            return (this.pageNo > 1)
        },
        nextFlag() {
            return (this.pageNo < this.totalPageCount)
        }
    },
    methods: {
        goPrev() {
            let no = this.pageNo - 1
            if (no >= 1) {
                this.$emit('goPage', no)
            }
        },
        goNext() {
            let no = this.pageNo + 1
            if (no < this.totalPageCount + 1) {
                this.$emit('goPage', no)
            }
        },
        goPage(no) {
            if (no == this.pageNo) {
                return
            }

            this.$emit('goPage', no)
        }
    }
}
</script>